import React, { FC } from 'react';
import { Route } from '../../models/site-links';
import { gaButtonEvent } from './../../services/visitor-info';
import './cta-buttons.css';
import './feature-buttons.css';

interface FeatureButtonProps {
  link: Route;
  linkText: string;
  buttonId: string;
  secondLink?: string;
  secondLinkText?: string;
  heroButton?: boolean;
  devButton?: boolean;
  hero?: boolean;
}

const Buttons: FC<FeatureButtonProps> = (props) => {
  let buttonType;
  if (props.hero) {
    buttonType = (
      <div className="hero-button">
        <a
          href={props.link}
          onClick={() => gaButtonEvent(props.linkText, props.buttonId)}
          className="cta-button"
        ></a>
      </div>
    );
  }

  if (props.heroButton) {
    buttonType = (
      <div className="hero-cta-button">
        {props.link && (
          <a
            href={props.link}
            className="primary-cta-button"
            onClick={() => gaButtonEvent(props.linkText, props.buttonId)}
          >
            {props.linkText}
          </a>
        )}
        {props.secondLink && (
          <a
            href={props.secondLink}
            className="alternate-cta-button"
            onClick={() => gaButtonEvent(props.linkText, props.buttonId)}
          >
            {props.secondLinkText}
          </a>
        )}
      </div>
    );
  } else if (props.devButton) {
    buttonType = (
      <div className="feature-buttons">
        {props.link && (
          <a
            href={props.link}
            className="dev-hero-button"
            onClick={() => gaButtonEvent(props.linkText, props.buttonId)}
          >
            {props.linkText}
          </a>
        )}
      </div>
    );
  } else {
    buttonType = (
      <div className="feature-buttons">
        {props.link && (
          <a
            href={props.link}
            className="primary-cta-button"
            onClick={() => gaButtonEvent(props.linkText, props.buttonId)}
          >
            {props.linkText}
          </a>
        )}
        {props.secondLink && (
          <a
            href={props.secondLink}
            className="alternate-cta-button"
            onClick={() => gaButtonEvent(props.linkText, props.buttonId)}
          >
            {props.secondLinkText}
          </a>
        )}
      </div>
    );
  }

  return <div>{buttonType}</div>;
};

export default Buttons;
