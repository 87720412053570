import React, { FC, ReactElement } from 'react';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import './hero-banner.css';
import Buttons from '../../components/buttons/feature-buttons';
import AnchorLinkButton from '../buttons/anchor-link-button';
import { Route } from '../../models/site-links';

interface HeroProps {
  heroImage: any;
  banner?: ReactElement;
  heading?: string;
  subHeading?: string;
  headingText?: string;
  showVideo?: boolean;
  videoLink?: string;
  featureImage?: any;
  buttonId: string;
  link?: Route;
  linkText?: string;
  secondLink?: string;
  secondLinkText?: string;
  anchorLink?: string;
  anchorLinkText?: string;
  small?: boolean;
}

const HeroBanner: FC<HeroProps> = (props) => {
  let heroMedia;

  if (props.showVideo) {
    heroMedia = (
      <iframe
        className="hero-video"
        height="500px"
        src={props.videoLink}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    );
  } else if (props.small) {
    heroMedia = <Img className="hero-small-feature" fluid={props.featureImage} />;
  } else {
    heroMedia = <div className="hero-feature-image" />;
  }

  return (
    <BackgroundImage className="w-full" fluid={props.heroImage}>
      <section id="header" className="pt-12 w-full">
        {props.banner}
        <div className="hero-container">
          {props.small && <div className="hero-container-sm"></div>}
          <div className="hero-textblock">
            <h1 className="text-white font-heading mb-4 font-bold leading-none hero-tagline">
              {props.heading}
            </h1>
            <h4 className="text-white hero-sub-heading">{props.subHeading}</h4>
            <p className="text-white text-xl font-hairline max-w-2xl leading-relaxed hero-headingText">
              {props.headingText}
            </p>
            {props.link && (
              <div className="hero-buttons">
                <Buttons
                  buttonId={props.buttonId}
                  heroButton
                  link={props.link}
                  linkText={props.linkText}
                  secondLink={props.secondLink}
                  secondLinkText={props.secondLinkText}
                />
              </div>
            )}
            {props.anchorLink && (
              <div className="hero-buttons">
                <AnchorLinkButton heroButton link={props.anchorLink} linkText={props.linkText} />
              </div>
            )}
          </div>
          {heroMedia}
        </div>
      </section>
    </BackgroundImage>
  );
};

export default HeroBanner;
