import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Route } from '../../models/site-links';
import Buttons from '../buttons/feature-buttons';
import './connect-with-us.css';

interface ConnectWithUsProps {
  promptText: string;
  buttonId: string;
}

const ConnectWithUs: FC<ConnectWithUsProps> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      callUs: file(relativePath: { eq: "icons/call_us.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="connect-container">
      <div className="connect-logo"></div>
      <div className="leading-none text-3xl font-heading text-blue connect-prompt">
        <h2>Connect with us</h2>
      </div>
      <div className="text-2xl connect-subprompt">
        <h2>{props.promptText}</h2>
      </div>
      <div className="connect-buttons">
        <Buttons
          link={Route.TalkToAnExpert}
          buttonId={'Connect with Us'}
          linkText="Get Pricing"
          secondLink="/talk-to-an-expert"
          secondLinkText="Request Info"
        />
      </div>
    </div>
  );
};

export default ConnectWithUs;
