import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ProductCard } from '../models/product-card';
import SEO from '../components/seo';
import WhyPatronscan from '../components/sections/why-patronscan';
import HeroBanner from '../components/sections/hero-banner';
import { getDemoLink } from '../services/demo-selector';
import Feature from '../components/sections/industry/feature-block';
import ConnectWithUs from '../components/sections/connect-with-us';
import { Route } from '../models/site-links';
import './bar-and-club-stats.css';

const BarAndClubStats = () => {
  const data = useStaticQuery(graphql`
    query {
      kioskLite: file(relativePath: { eq: "product-card/kiosk.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      kioskPlus: file(relativePath: { eq: "product-card/kiosk-plus.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      handheld: file(relativePath: { eq: "product-card/handheld.png" }) {
        childImageSharp {
          fixed(height: 118) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      whiteCountertop: file(relativePath: { eq: "feature-images/white-scanner.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      barNightclub: file(relativePath: { eq: "benefit-images/bar-nightclub.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      benefitsGuestlist: file(relativePath: { eq: "benefit-images/benefits-guestlists.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDHero: file(relativePath: { eq: "hero-images/fakeID-hero.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO title="Bar and Club Stats - Patronscan" />
      <HeroBanner
        heroImage={data.fakeIDHero.childImageSharp.fluid}
        heading="Trusted Bar and Club stats"
        subHeading="ID scanners to protect your patrons, license and profit."
        videoLink="https://player.vimeo.com/video/366101059?autoplay=1&loop=1&autopause=0&muted=1"
        showVideo
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="BarClubStatsHero:BookDemo"
      />
      <section>
        <Feature
          image={data.whiteCountertop.childImageSharp.fluid}
          heading="Leading ID scanners"
          headingThin="for bar and club stats"
          subHeading="Bar and club stats are collected from using the leading ID scanners in the nightclub industry."
          description="Patronscan ID scanners are easy to use, lightning-fast, and powerful, giving you a powerful security system to keep troublemakers and minors out of your venue. Patronscan ID scanners can read over 3,600 types of government-issued ID, including all driver’s licenses and passports. The scanner will determine whether the ID is a valid or fake ID, and tell you the age of the patron, making it easy to keep minors out of your venue."
          linkText="Contact Us"
          link={Route.TalkToAnExpert}
          buttonId="BarClubStatsFeature:LeadingIDScanners"
        />
        <Feature
          rightAlign
          image={data.barNightclub.childImageSharp.fluid}
          heading="Bar and club stats"
          headingThin="for peace of mind"
          subHeading="Spot underaged patrons quickly, no matter how they try getting in. Receive alerts for suspected minors."
          description="If a minor is passed an ID, then the ID scanner will notify you that the ID is being scanned multiple times that night, and show you a live image of the person who scanned in with that ID previously, making it easy to stop ID passing."
          linkText="Book Demo"
          link={getDemoLink()}
          buttonId="BarClubStatsFeature:PeaceOfMind"
        />
        <Feature
          image={data.benefitsGuestlist.childImageSharp.fluid}
          heading="Powerful insights for"
          headingThin="your bar or nightclub"
          subHeading="Every day, the bar and club stats report will be sent to you by email."
          description="This daily email will go over the bar and club stats from the night before, providing you with valuable information about the demographics and behavior of your patrons."
          linkText="Contact Us"
          link={Route.TalkToAnExpert}
          buttonId="BarClubStatsFeature:PowerfulInsights"
        />
      </section>
      <WhyPatronscan />
      <div className="bar-club-stats-connect">
        <ConnectWithUs
          buttonId="BarClubStatsConnect"
          promptText="Request pricing from one of our experts or book a live demo to see Patronscan's ID scanners in action."
        />
      </div>
    </div>
  );
};

export default BarAndClubStats;
