import React, { FC } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import './cta-buttons.css';
import './feature-buttons.css';

interface AnchorLinkButtonProps {
  link: string;
  linkText: string;
  heroButton: boolean;
}

const AnchorLinkButton: FC<AnchorLinkButtonProps> = (props) => {
  return (
    <div className="hero-cta-button">
      <AnchorLink to={props.link} className="industry-cta-button">
        <span>{props.linkText}</span>
      </AnchorLink>
    </div>
  );
};

export default AnchorLinkButton;
